<template>
  <div id="" class="loader">
    <svg class="mainSVG" viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path
            id="puff"
            d="M4.5,8.3C6,8.4,6.5,7,6.5,7s2,0.7,2.9-0.1C10,6.4,10.3,4.1,9.1,4c2-0.5,1.5-2.4-0.1-2.9c-1.1-0.3-1.8,0-1.8,0
  s-1.5-1.6-3.4-1C2.5,0.5,2.1,2.3,2.1,2.3S0,2.3,0,4.4c0,1.1,1,2.1,2.2,2.1C2.2,7.9,3.5,8.2,4.5,8.3z"
            fill="#000"
        />
        <circle id="dot" cx="0" cy="0" r="5" fill="#fff"/>
      </defs>

      <circle
          id="mainCircle"
          fill="none"
          stroke="none"
          stroke-width="2"
          stroke-miterlimit="10"
          cx="400"
          cy="300"
          r="130"
      />
      <circle
          id="circlePath"
          fill="none"
          stroke="none"
          stroke-width="2"
          stroke-miterlimit="10"
          cx="400"
          cy="300"
          r="80"
      />

      <g id="mainContainer">
        <g id="carLoader">
          <path
              id="carRot"
              fill="#e03432"
              d="M45.6,16.9l0-11.4c0-3-1.5-5.5-4.5-5.5L3.5,0C0.5,0,0,1.5,0,4.5l0,13.4c0,3,0.5,4.5,3.5,4.5l37.6,0
  C44.1,22.4,45.6,19.9,45.6,16.9z M31.9,21.4l-23.3,0l2.2-2.6l14.1,0L31.9,21.4z M34.2,21c-3.8-1-7.3-3.1-7.3-3.1l0-13.4l7.3-3.1
  C34.2,1.4,37.1,11.9,34.2,21z M6.9,1.5c0-0.9,2.3,3.1,2.3,3.1l0,13.4c0,0-0.7,1.5-2.3,3.1C5.8,19.3,5.1,5.8,6.9,1.5z M24.9,3.9
  l-14.1,0L8.6,1.3l23.3,0L24.9,3.9z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script setup>
const route = useRoute();
const animationInstance = ref(null);

function initLoader() {
  if (typeof TweenMax === 'undefined' || typeof MorphSVGPlugin === 'undefined') {
    return;
  }
  if (animationInstance.value) {
    // If the animation is already running, reset it or reload it
    animationInstance.value.restart();
    return;
  }
  TweenMax.set("#circlePath", {
    attr: {
      r: document.querySelector("#mainCircle").getAttribute("r"),
    },
  });
  MorphSVGPlugin.convertToPath("#circlePath");

  var xmlns = "http://www.w3.org/2000/svg",
      xlinkns = "http://www.w3.org/1999/xlink",
      select = function (s) {
        return document.querySelector(s);
      },
      selectAll = function (s) {
        return document.querySelectorAll(s);
      },
      mainCircle = select("#mainCircle"),
      mainContainer = select("#mainContainer"),
      car = select("#carLoader"),
      mainSVG = select(".mainSVG"),
      mainCircleRadius = Number(mainCircle.getAttribute("r")),
      // radius = mainCircleRadius,
      numDots = mainCircleRadius / 2,
      step = 360 / numDots,
      dotMin = 0,
      circlePath = select("#circlePath");

  //
  // mainSVG.appendChild(circlePath);
  TweenMax.set("svg", {
    visibility: "visible",
  });
  TweenMax.set([car], {
    transformOrigin: "50% 50%",
  });
  TweenMax.set("#carRot", {
    transformOrigin: "0% 0%",
    rotation: 30,
  });

  var circleBezier = MorphSVGPlugin.pathDataToBezier(circlePath.getAttribute("d"), {
    offsetX: -20,
    offsetY: -5,
  });

  //console.log(circlePath)
  var mainTl = new TimelineMax();

  function makeDots() {
    var d, angle, tl;
    for (var i = 0; i < numDots; i++) {
      d = select("#puff").cloneNode(true);
      mainContainer.appendChild(d);
      angle = step * i;
      TweenMax.set(d, {
        //attr: {
        x: Math.cos((angle * Math.PI) / 180) * mainCircleRadius + 400,
        y: Math.sin((angle * Math.PI) / 180) * mainCircleRadius + 300,
        rotation: Math.random() * 360,
        transformOrigin: "50% 50%",
        //}
      });

      tl = new TimelineMax({
        repeat: -1,
      });
      tl.from(d, 0.2, {
        scale: 0,
        ease: Power4.easeIn,
      }).to(d, 1.8, {
        scale: Math.random() + 2,
        alpha: 0,
        ease: Power4.easeOut,
      });

      mainTl.add(tl, i / (numDots / tl.duration()));
    }
    var carTl = new TimelineMax({
      repeat: -1,
    });
    carTl.to(car, tl.duration(), {
      bezier: {
        type: "cubic",
        values: circleBezier,
        autoRotate: true,
      },
      ease: Linear.easeNone,
    });
    mainTl.add(carTl, 0.05);
  }

  makeDots();
  mainTl.time(120);
  TweenMax.to(mainContainer, 20, {
    rotation: -360,
    svgOrigin: "400 300",
    repeat: -1,
    ease: Linear.easeNone,
  });
  mainTl.timeScale(1.1);
  animationInstance.value = mainTl;
}


// Watch for route changes
watch(() => route.path, (newPath, oldPath) => {
  // Your logic here
  initLoader();
});
onMounted(() => {
  initLoader();
});
</script>
